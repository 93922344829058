import { Permission, Project, ProjectAccessPolicy, SubjectType, Team, TeamMember } from '@pollination-solutions/pollination-sdk'
import { notification } from 'antd'
import { useAuth } from 'auth-context'
import { useCallback, useEffect, useMemo, useState } from 'react'

export interface TeamWithMembers extends Team {
  members: TeamMember[]
}

export interface TeamPermission {
  team: TeamWithMembers,
  permission: Permission
}

interface useProjectTeamsProps {
  initialProject: Project
  organizationTeams: TeamWithMembers[]
}

const useProjectTeams = ({ initialProject, organizationTeams }: useProjectTeamsProps) => {
  const { client } = useAuth()

  const [project, setProject] = useState<Project>(initialProject)
  const [accessPolicies, setAccessPolicies] = useState<ProjectAccessPolicy[]>([])

  useEffect(() => {
    setProject(initialProject)
  }, [initialProject])

  const fetchAccessPermissions = useCallback(async () => {
    const { data } = await client.projects.getProjectAccessPermissions({
      owner: project.owner.name,
      name: project.name,
      perPage: 100,
    })
    setAccessPolicies(data.resources)
  }, [client, project, setAccessPolicies])

  useEffect(() => {
    fetchAccessPermissions()
  }, [project, fetchAccessPermissions])

  const teamPermissions = useMemo(() => {
    const tps = accessPolicies?.map((accessor) => {
      const team = organizationTeams.find(t => t.slug === accessor.subject.name)
      if (!team) {
        return
      }
      return {
        team: team,
        permission: accessor.permission
      }
    }) ?? []

    return tps.filter(tp => tp !== undefined) as TeamPermission[]
  }, [accessPolicies, organizationTeams])

  const upsertTeamPermission = useCallback(async (team: Team, permission: Permission) => {
    const newAccessPolicy = {
      permission,
      subject: {
        name: team.slug,
        account_type: 'team',
        id: team.id,
        slug: team.slug,
        subject_type: SubjectType.Team
      }
    }

    return client.projects.upsertProjectPermission({ owner: project.owner.name, name: project.name, projectAccessPolicy: newAccessPolicy })
      .then(() => {
        return fetchAccessPermissions()
      })
  }, [client, project, fetchAccessPermissions])

  const removeTeamPermission = useCallback(async (team: Team) => {
    const accessorIndex = accessPolicies?.findIndex(a => a.subject.name === team.slug)
    if (accessorIndex === undefined) {
      return
    }

    const accessPolicy = {
      name: team.slug,
      subject_type: SubjectType.Team,
    }

    return client.projects.deleteProjectOrgPermission({ owner: project.owner.name, name: project.name, projectPolicySubject: accessPolicy })
      .then(() => {
        fetchAccessPermissions()
      })
      .catch((error) => {
        notification.error({
          message: 'Error removing team from project pool.'
        })
      })
  }, [client, project, fetchAccessPermissions, accessPolicies])

  return {
    project,
    teamPermissions,
    upsertTeamPermission,
    removeTeamPermission
  }
}

export default useProjectTeams