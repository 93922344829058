import { AccountPublic, Team, TeamMember } from '@pollination-solutions/pollination-sdk'
import { notification } from 'antd'
import { useAuth } from 'auth-context'
import { useCallback, useEffect, useState } from 'react'

export interface TeamWithMembers extends Team {
  members: TeamMember[]
}

const useOrganisationTeams = (account?: AccountPublic) => {
  const { client } = useAuth()

  const [teams, setTeams] = useState<TeamWithMembers[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const fetchTeams = useCallback(async (orgName: string) => {
    try {
      const teamsResponse = await client.teams.listOrgTeams({ orgName, perPage: 100 })

      const teamsWithMembers = teamsResponse.data.resources.map(async (team) => {
        const teamSlug = team.slug.split('/').pop() as string
        const membersResponse = await client.teams.getOrgTeamMembers({ orgName, teamSlug })
        return {
          ...team,
          members: membersResponse.data.resources
        }
      })

      return Promise.all(teamsWithMembers)
    } catch (err) {
      notification.error({ message: 'Failed to fetch teams', description: err })
    }
    return Promise.resolve([])
  }, [client])

  useEffect(() => {
    if (!account || account.account_type != 'org') return

    setLoading(true)
    fetchTeams(account.name)
      .then((teams) => {
        setTeams(teams)
      }
      )
      .finally(() => setLoading(false))
  }, [fetchTeams, account])

  return { teams, loading }
}

export default useOrganisationTeams