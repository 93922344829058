import { LicensePoolPublic, Permission, SubjectType, Team, TeamMember } from '@pollination-solutions/pollination-sdk'
import { notification } from 'antd'
import { useAuth } from 'auth-context'
import { useCallback, useEffect, useMemo, useState } from 'react'

export interface TeamWithMembers extends Team {
  members: TeamMember[]
}

export interface TeamPermission {
  team: TeamWithMembers,
  permission: Permission
}

interface useLicenseTeamsProps {
  initialLicense: LicensePoolPublic
  organizationTeams: TeamWithMembers[]
}

const useLicenseTeams = ({ initialLicense, organizationTeams }: useLicenseTeamsProps) => {
  const { client } = useAuth()

  const [license, setLicense] = useState<LicensePoolPublic>(initialLicense)


  useEffect(() => {
    setLicense(initialLicense)
  }, [initialLicense])

  const teamPermissions = useMemo(() => {
    const tps = license.accessors?.map((accessor) => {
      const team = organizationTeams.find(t => t.id === accessor.subject.id)
      if (!team) {
        return
      }
      return {
        team: team,
        permission: accessor.permission
      }
    }) ?? []

    return tps.filter(tp => tp !== undefined) as TeamPermission[]
  }, [license, organizationTeams])

  const upsertTeamPermission = useCallback(async (team: Team, permission: Permission) => {
    const newAccessPolicy = {
      permission,
      subject: {
        name: team.slug,
        account_type: 'team',
        id: team.id,
        slug: team.slug,
        subject_type: SubjectType.Team
      }
    }


    return client.licenses.grantAccessToPool({ poolId: license.id, licensePoolAccessPolicyList: { resources: [newAccessPolicy] } })
      .then(({ data }) => {
        setLicense(data)
      })
  }, [client, license])

  const removeTeamPermission = useCallback(async (team: Team) => {
    const accessorIndex = license.accessors?.findIndex(a => a.subject.id === team.id)
    if (accessorIndex === undefined) {
      return
    }

    const accessPolicy = {
      name: team.slug,
      subject_type: SubjectType.Team,
    }

    return client.licenses.revokeAccessToPool({ poolId: license.id, licensePoolPolicySubjectList: { resources: [accessPolicy] } })
      .then(({ data }) => {
        setLicense(data)
      })
      .catch((error) => {
        notification.error({
          message: 'Error removing team from license pool.'
        })
      })
  }, [client, license])

  return {
    license,
    teamPermissions,
    upsertTeamPermission,
    removeTeamPermission
  }
}

export default useLicenseTeams