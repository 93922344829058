import React, { useState } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'



interface DeleteButtonProps {
  title: string
  onClick: () => Promise<void>
}

const DeleteButton: React.FunctionComponent<DeleteButtonProps> = ({ title, onClick }) => {
  const [loading, setLoading] = useState<boolean>(false)
  return (
    <Tooltip title={title}>
      <Button shape='circle'
        loading={loading}
        onClick={() => {
          setLoading(true)
          onClick().finally(() => setLoading(false))
        }}
        danger
        ghost
      >
        {!loading && <DeleteOutlined style={{ display: 'flex', justifyContent: 'center' }} />}
      </Button>
    </Tooltip>
  )
}

export default DeleteButton