import { AccountPublic, LicensePoolPublic, Permission, SubjectType } from '@pollination-solutions/pollination-sdk'
import { notification } from 'antd'
import { useAuth } from 'auth-context'
import { useCallback, useEffect, useMemo, useState } from 'react'


export interface UserPermission {
  user: AccountPublic,
  permission: Permission
}

interface useLicenseUsersProps {
  initialLicense: LicensePoolPublic
}

const useLicenseUsers = ({ initialLicense }: useLicenseUsersProps) => {
  const { client } = useAuth()

  const [license, setLicense] = useState<LicensePoolPublic>(initialLicense)

  useEffect(() => {
    setLicense(initialLicense)
  }, [initialLicense])


  const userPermissions = useMemo(() => {
    return license.accessors?.filter(a => a.subject.account_type === SubjectType.User)
      .map((a) => ({
        user: a.subject as AccountPublic,
        permission: a.permission
      })) ?? []
  }, [license])

  const removeUserPermission = useCallback(async (user: AccountPublic) => {

    const accessorIndex = license.accessors?.findIndex(a => a.subject.id === user.id)
    if (!accessorIndex) return

    const accessPolicy = {
      name: user.name,
      subject_type: SubjectType.User,
    }

    return client.licenses.revokeAccessToPool({ poolId: license.id, licensePoolPolicySubjectList: { resources: [accessPolicy] } })
      .then(({ data }) => {
        setLicense(data)
      })
      .catch((error) => {
        notification.error({
          message: 'Error removing user from license pool.'
        })
      })
  }, [client, license])

  return {
    license,
    userPermissions,
    removeUserPermission
  }
}

export default useLicenseUsers