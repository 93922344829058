import React, { useCallback } from 'react'

import { Loading3QuartersOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Row, Space, Tooltip, Typography } from 'antd'

import { AccountPublic, ActivationList, LicensePoolPublic } from '@pollination-solutions/pollination-sdk'

import { AvatarLink } from 'atoms'

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useHistory } from 'react-router-dom'

export interface LicensePoolSummaryProps {
  pool: LicensePoolPublic
  activations?: ActivationList
  account?: AccountPublic
}

export const LicensePoolSummary: React.FunctionComponent<LicensePoolSummaryProps> = ({
  pool,
  activations,
  account }) => {

  const history = useHistory()

  const screens = useBreakpoint()

  const formattedProductKey = useCallback((productKey: string) => {
    return productKey.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }, [])

  return (
    <Row justify='space-between' style={{ width: '100%' }} >
      <Col>
        <Space align='center' size={48} >
          <Space size={12}>
            <Typography.Title level={4} style={{ marginBottom: 0 }}>Your {formattedProductKey(pool.product)} License Pool</Typography.Title>
          </Space>
          <Tooltip title='Current Activations / Total Floating Licenses' overlayInnerStyle={{ textAlign: 'center' }}>
            {!activations || !activations.resources ?
              <Loading3QuartersOutlined spin style={{ color: '#d0d7de' }} />
              :
              <Typography.Title level={5} style={{ marginBottom: 0, fontWeight: 400 }} code>{activations.resources.length} / {pool.allowed_activations}</Typography.Title>
            }
          </Tooltip>
        </Space>
      </Col>
      <Col>
        {screens.lg && pool && pool.accessors &&
          <Space align='center' split={<Divider type='vertical' />}>
            <Tooltip title='Owner'>
              <Col>
                <AvatarLink src={pool.owner.picture_url} link={`/${pool.owner.name}`} key={`${pool.owner.name}-owner`} />
              </Col>
            </Tooltip>
            <Tooltip title='Buy Floating Licenses'>
              <Button icon={<PlusOutlined />} shape='circle' onClick={() => history.push(`/${account?.name}?tab=subscription`)}></Button>
            </Tooltip>
          </Space>
        }
      </Col>
      <Row style={{ width: '100%', marginTop: 8 }}>
        {pool.description ?
          <Typography.Text type='secondary'>{pool.description}</Typography.Text>
          :
          <Typography.Text type='secondary'>{'This is the pool of floating licenses you\'ve purchased.'}</Typography.Text>
        }
      </Row>
    </Row>
  )
}