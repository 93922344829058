import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { TeamOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Divider, List, notification, Select, Space, Typography } from 'antd'
import debounce from 'lodash.debounce'

import { Permission, Team } from '@pollination-solutions/pollination-sdk'

import { InputLabel } from 'atoms'
import DeleteButton from 'atoms/DeleteButton'
import SelectPermission from 'atoms/SelectPermission'
import { TeamPermission } from 'hooks/useLicenseTeams'


interface SearchTeamsProps {
  searchTeams: (search?: string) => Promise<Team[]>
  selectTeam: (teams: Team) => void
}

const SearchTeams: React.FunctionComponent<SearchTeamsProps> = ({ searchTeams, selectTeam }) => {

  const [search, setSearch] = useState<string>('')
  const [teams, setTeams] = useState<Team[]>([])

  const debounceSearch = useMemo(() => debounce((search: string) => setSearch(search), 200), [])

  useEffect(() => {
    searchTeams(search).then((teams) => setTeams(teams))
      .catch(error => {
        notification.error({
          message: error.data.details
        })
      })
  }, [search, searchTeams, setTeams])

  return (
    <Select
      showSearch
      showArrow
      placeholder='Search Teams'
      onSearch={debounceSearch}
      onSelect={(value) => {
        const team = teams.find(team => team.slug === value)
        if (team) {
          selectTeam(team)
        }
      }}
    >
      {
        teams.map(team => (
          <Select.Option key={team.name} value={team.slug}>
            {team.name}
          </Select.Option>
        ))
      }
    </Select>
  )
}


export interface LicensePoolTeamAccessListProps {
  loading: boolean,
  teamPermissions: TeamPermission[]
  canAdd: boolean
  permissionOptions: Permission[]
  searchTeams: (search?: string) => Promise<Team[]>
  upsertTeamPermission: (team: Team, permission: Permission) => Promise<void>
  removeTeamPermission: (team: Team) => Promise<void>
}

export const ResourceTeamAccessList: React.FunctionComponent<LicensePoolTeamAccessListProps> = ({
  loading,
  teamPermissions,
  canAdd,
  permissionOptions,
  searchTeams,
  upsertTeamPermission,
  removeTeamPermission,
}) => {

  const [teamToAdd, setTeamToAdd] = useState<Team | undefined>()
  const [permissionToAdd, setPermissionToAdd] = useState<Permission>(permissionOptions[0])
  const [upsertPermissionLoading, setUpsertPermissionLoading] = useState<boolean>(false)

  const upsertPermission = useCallback(() => {
    if (!teamToAdd) return
    setUpsertPermissionLoading(true)
    upsertTeamPermission(teamToAdd, permissionToAdd)
      .then(() => {
        setTeamToAdd(undefined)
      })
      .catch(error => {
        notification.error({
          message: error.data.details
        })
      })
      .finally(() => setUpsertPermissionLoading(false))
  }, [teamToAdd, permissionToAdd, upsertTeamPermission])

  const removePermission = useCallback((team: Team) => {
    return removeTeamPermission(team)
      .then(() => {
        setTeamToAdd(undefined)
      })
      .catch(error => {
        notification.error({
          message: error.data.details
        })
      })
  }, [removeTeamPermission])

  return (
    <List
      style={{ width: '100%' }}
      loading={loading}
      dataSource={teamPermissions}
      header={
        <Col span={24}>
          <Space style={{ width: '100%' }} direction='vertical'>
            <Typography.Text>Teams who have access.</Typography.Text>
          </Space>
        </Col>
      }
      footer={
        canAdd &&
        <InputLabel label='Add Teams' >
          <Space align='center'>
            <Button icon={<TeamOutlined />} shape='circle'
              disabled={!teamToAdd}
              loading={upsertPermissionLoading}
              type='primary'
              onClick={upsertPermission} />
            <Divider type='vertical' />
            <SearchTeams searchTeams={loading ? (search?: string) => (Promise.resolve([] as Team[])) : searchTeams} selectTeam={setTeamToAdd} />
            <SelectPermission permissionOptions={permissionOptions} value={permissionToAdd} onChange={(value) => Promise.resolve(setPermissionToAdd(value))} />
          </Space>
        </InputLabel>
      }
      renderItem={(teamPermission) =>
        <>
          <List.Item actions={[
            <SelectPermission key={teamPermission.team.id} permissionOptions={permissionOptions} value={teamPermission.permission} onChange={(value) => upsertTeamPermission(teamPermission.team, value)} />,
            <DeleteButton key={teamPermission.team.id} title={'Remove Access'} onClick={() => removePermission(teamPermission.team)} />
          ]}>
            <List.Item.Meta
              title={teamPermission.team.name}
              description={teamPermission.team.description}
              avatar={<Avatar.Group maxCount={3}>
                {teamPermission.team.members.map(member => (
                  <Avatar key={member.user.username} src={member.user.picture} />
                ))}
              </Avatar.Group>}
            />
          </List.Item>
        </>
      }
    />
  )
}
