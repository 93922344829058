import { FrownOutlined } from '@ant-design/icons'
import { Card, Space, Typography } from 'antd'
import { useAccount } from 'hooks'
import useOrganisationTeams, { TeamWithMembers } from 'hooks/useOrganisationTeams'
import useProjectTeams from 'hooks/useProjectTeams'
import { ResourceTeamAccessList } from 'molecules/ResourceTeamAccessList'
import React, { useCallback, useContext } from 'react'
import context from './context'

/** Lists access policies set on the current project and allows team-based addition + modification. */
const Collaborators = (): React.ReactElement => {
  const { project } = useContext(context)

  const { account } = useAccount()
  const { teams, loading: teamsLoading } = useOrganisationTeams(account)
  const { teamPermissions, upsertTeamPermission, removeTeamPermission } = useProjectTeams({
    initialProject: project, organizationTeams: teams
  })

  const searchTeams = useCallback(async (search?: string) => {
    let matchingTeams = [] as TeamWithMembers[]
    if (!search) {
      matchingTeams = teams
    } else {
      matchingTeams = teams.filter(team => team.name.toLowerCase().includes(search?.toLowerCase() || ''))
    }
    // exclude teams that are already in the teamPermissions list
    return matchingTeams.filter(team => !teamPermissions.find(tp => tp.team.id === team.id))
  }, [teams, teamPermissions])

  return (
    <Card>
      {project.owner.account_type === 'org' ? (
        <ResourceTeamAccessList
          loading={teamsLoading}
          teamPermissions={teamPermissions}
          canAdd={true}
          permissionOptions={['read', 'write', 'admin']}
          searchTeams={searchTeams}
          upsertTeamPermission={upsertTeamPermission}
          removeTeamPermission={removeTeamPermission}
        />
      ) : (
        <Space>
          <FrownOutlined style={{ fontSize: '24px' }} />
          <Typography.Text>{'You can\'t add collaborators on personal projects'}</Typography.Text>
        </Space>
      )}
    </Card>
  )
}

export default Collaborators
